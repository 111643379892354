/* src/index.css */
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', Arial, sans-serif;
  background-color: #0d1b2a54;
}

@media print {
  /* Oculta todos os elementos da página */
  body * {
    visibility: hidden;
  }
  
  /* Torna visível apenas o conteúdo da área de impressão */
  #print-area, #print-area * {
    visibility: visible;
  }
  
  /* Posiciona a área de impressão no topo da página */
  #print-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
